import React, { useState, useMemo } from 'react';
import uri from '../../config';
import { Doughnut, Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
} from 'chart.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Table, Form, Button } from 'react-bootstrap';
import spurtrack from './spurtrack.png'
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import 'jspdf-autotable';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);


const colorPalette = [
    'rgb(255, 99, 132)',   // Red
    'rgb(54, 162, 235)',   // Blue
    'rgb(255, 206, 86)',   // Yellow
    'rgb(153, 102, 255)',  // Purple
    'rgb(255, 159, 64)',   // Orange
    'rgb(201, 203, 207)',  // Light gray
    'rgb(75, 192, 192)',   // Teal
    'rgb(255, 99, 71)',    // Tomato
    'rgb(147, 112, 219)',  // Medium Purple
    'rgb(60, 179, 113)',   // Medium Sea Green
    'rgb(123, 104, 238)',  // Medium Slate Blue
    'rgb(0, 128, 128)',    // Teal
    'rgb(245, 130, 48)',   // Orange Red
    'rgb(220, 20, 60)',    // Crimson
    'rgb(65, 105, 225)',   // Royal Blue
];

// Helper function to convert minutes to hours with two decimal points
const formatDurationInHours = (minutes) => {
    return (minutes / 60).toFixed(2);
};

// Helper function to format date
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day} ${month} ${year}`;
};

const getWeekFromISODate = (isoDate) => {
    const date = new Date(isoDate);
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

const getMonthFromISODate = (isoDate) => {
    return new Date(isoDate).toLocaleString('default', { month: 'short' });
};

// Add this helper function to get max measurements count
const getMaxMeasurementsCount = (data) => {
    return Math.max(
        2, // minimum of 2 columns
        ...data.map(item => item._id.measurements?.length || 0)
    );
};

// Update the groupData function with more robust measurement value handling
const groupData = (data, groupBy) => {
    const groupedData = {};
    const maxMeasurements = groupBy === 'Task' ? getMaxMeasurementsCount(data) : 0;

    data.forEach(item => {
        const group = getGroupValue(item, groupBy);

        if (!groupedData[group]) {
            groupedData[group] = {
                group,
                projects: [],
                totalDuration: 0,
                rawDate: item._id.date || null,
                measurements: groupBy === 'Task' ? Array(maxMeasurements).fill(null).map(() => ({
                    total: 0,
                    measurementNames: new Set()
                })) : []
            };
        }

        const projectName = item._id.projectName || 'Unknown Project';
        if (!groupedData[group].projects.includes(projectName)) {
            groupedData[group].projects.push(projectName);
        }

        groupedData[group].totalDuration += parseFloat(formatDurationInHours(item.totalTime)) || 0;

        // Add measurements only for Task grouping
        if (groupBy === 'Task') {
            item._id.measurements?.forEach((measurement, index) => {
                if (index < maxMeasurements) {
                    let value = 0;
                    
                    // Check if the value exists
                    if (measurement.value) {
                        // Convert to lowercase for case-insensitive comparison
                        const lowerValue = measurement.value.toLowerCase();
                        
                        // Check for NA variations
                        if (['na', 'n/a', 'n.a.', 'not applicable'].includes(lowerValue)) {
                            value = 0;
                        } else if (measurement.value.includes('-')) {
                            // Handle range values (take first number)
                            const firstNumber = measurement.value.split('-')[0].trim();
                            value = !isNaN(parseFloat(firstNumber)) ? parseFloat(firstNumber) : 0;
                        } else {
                            // Handle regular numeric values
                            value = !isNaN(parseFloat(measurement.value)) ? parseFloat(measurement.value) : 0;
                        }
                    }
                    
                    groupedData[group].measurements[index].total += value;
                    groupedData[group].measurements[index].measurementNames.add(measurement.measurementName);
                }
            });
        }
    });

    return Object.values(groupedData);
};

// Update getGroupValue to return formatted group value
const getGroupValue = (item, groupBy) => {
    switch (groupBy) {
        case 'Project':
            return item._id.projectName;
        case 'Week':
            return `Week ${getWeekFromISODate(item._id.date)}`;
        case 'Month':
            return getMonthFromISODate(item._id.date);
        case 'Date':
            return formatDate(item._id.date);
        case 'Client':
            return item._id.clientName;
        case 'User':
            return `${item._id.userName} `; // Combine Team and User for hierarchical grouping
        case 'Task':  
            return item._id.taskName;
        case 'Description':
            return item._id.description;
        default:
            return '';
    }
};

const groupByProject = (data) => {
    const grouped = {};

    data.forEach((item) => {
        const projectName = item._id.projectName;
        const duration = parseFloat(formatDurationInHours(item.totalTime));

        if (grouped[projectName]) {
            grouped[projectName].totalDuration += duration;
        } else {
            grouped[projectName] = {
                projectName: projectName,
                clientName: item._id.clientName,
                totalDuration: duration,
            };
        }
    });

    return Object.values(grouped); // Convert the grouped object back to an array
};

// Move helper functions outside the component
const getChartData = (data, colors) => {
    return {
        labels: data.map(g => g.group),
        datasets: [{
            data: data.map(g => g.totalDuration),
            backgroundColor: colors,
            borderWidth: 0
        }]
    };
};

const generateColors = (count) => {
    const colorPalette = [
        '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
        '#FF9F40', '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'
    ];
    return Array(count).fill().map((_, index) => colorPalette[index % colorPalette.length]);
};

const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            callbacks: {
                label: function(context) {
                    return `${context.label}: ${context.raw} hours`;
                }
            }
        }
    }
};

const SummaryReportChart = ({ summaryData }) => {
    const [groupBy1, setGroupBy1] = useState('');
    const [groupBy2, setGroupBy2] = useState('');
    const [groupBy3, setGroupBy3] = useState('');
    const [modalData, setModalData] = useState(null);
    const [modalShow, setModalShow] = useState(false);

    const handleGroupChange = (e, setGroupBy) => {
        setGroupBy(e.target.value);
    };

    // Available group options
    const groupOptions = [
        'Project', 'Week', 'Month', 'Date', 'Client', 'User', 'Task', 'Description'
    ];

    // Filter options for each dropdown based on selected values
    const getFilteredOptions = (selected1, selected2, currentValue) => {
        return groupOptions.filter(option =>
            option !== selected1 && option !== selected2 || option === currentValue
        );
    };

    const groupedData1 = groupBy1 ? groupData(summaryData, groupBy1) : [];
    const groupedData2 = groupBy2 ? groupData(summaryData, groupBy2) : [];
    const groupedData3 = groupBy3 ? groupData(summaryData, groupBy3) : [];

    // Consolidated table data accumulation
    const consolidatedData = [...(groupedData1 || []), ...(groupedData2 || []), ...(groupedData3 || [])].reduce((acc, group) => {
        const existingGroup = acc.find(g => g.group === group.group);
        if (existingGroup) {
            existingGroup.totalDuration += group.totalDuration;
            existingGroup.projects = [...new Set([...existingGroup.projects, ...group.projects])];
        } else {
            acc.push({ ...group });
        }
        return acc;
    }, []);

    const sortDates = (dates) => {
        return dates.sort((a, b) => new Date(a) - new Date(b));
    };
    const projectNames = [...new Set(summaryData.map(item => item._id.projectName))];
    const aggregatedData = summaryData.reduce((acc, current) => {
        const formattedDate = formatDate(current._id.date); // Ensure date is formatted correctly
        const key = `${formattedDate}-${current._id.projectName}`;
        
        if (!acc[key]) {
            acc[key] = {
                date: formattedDate,
                projectName: current._id.projectName,
                totalTime: current.totalTime
            };
        } else {
            acc[key].totalTime += current.totalTime;  // Sum up time for same date/project
        }
    
        return acc;
    }, {});
    
    // Convert the aggregated data into arrays
    const labels = [...new Set(Object.values(aggregatedData).map(item => item.date))].sort(); // Sorted dates
    const datasets = projectNames.map((project, index) => {
        return {
            label: project,
            data: labels.map(label => {
                const entry = Object.values(aggregatedData).find(item => item.projectName === project && item.date === label);
                return entry ? formatDurationInHours(entry.totalTime) : 0;
            }),
            backgroundColor: colorPalette[index % colorPalette.length],
        };
    });

    const renderConcentricDoughnut = () => {
        const consolidatedChartData = {
            labels: [...groupedData1.map(g => g.group), ...groupedData2.map(g => g.group), ...groupedData3.map(g => g.group)],
            datasets: [
                {
                    label: groupBy1,
                    data: groupedData1.map(g => g.totalDuration),
                    backgroundColor: groupedData1.map((_, index) => colorPalette[index % colorPalette.length]),
                },
                {
                    label: groupBy2,
                    data: groupedData2.map(g => g.totalDuration),
                    backgroundColor: groupedData2.map((_, index) => colorPalette[(index + groupedData1.length) % colorPalette.length]),
                },
                {
                    label: groupBy3,
                    data: groupedData3.map(g => g.totalDuration),
                    backgroundColor: groupedData3.map((_, index) => colorPalette[(index + groupedData1.length + groupedData2.length) % colorPalette.length]),
                },
            ],
        };

        return (
            <div style={{ width: '75%', margin: 'auto' }}>
                <Doughnut
                    data={consolidatedChartData}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            tooltip: {
                                callbacks: {
                                    label: function (context) {
                                        const duration = context.raw;
                                        return `${duration} hours`;  // Display unit in tooltip
                                    }
                                }
                            },
                            legend: {
                                display: false
                            }
                        }
                    }}
                    height={400}
                    width={400}
                />
            </div>
        );
    };
    // Update the renderTable function to show measurements only for Task grouping
    const renderTable = (groupedData, chartColors, groupBy) => {
        const showMeasurements = groupBy === 'Task';
        const maxMeasurements = showMeasurements && groupedData.length > 0 ? groupedData[0].measurements.length : 0;

        return (
            <Table hover>
                <thead>
                    <tr>
                        <th>Group</th>
                        <th>Total Duration (hours)</th>
                        <th>Projects</th>
                        {showMeasurements && Array.from({ length: maxMeasurements }, (_, i) => (
                            <th key={`measurement-${i}`}>
                                Measurement {i + 1}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {groupedData.map((group, index) => (
                        <tr key={index}>
                            <td>
                                <span style={{
                                    display: 'inline-block',
                                    width: '8px',
                                    height: '8px',
                                    backgroundColor: chartColors[index],
                                    marginRight: '10px',
                                    borderRadius: '50%'
                                }}></span>
                                {group.group}
                            </td>
                            <td>{group.totalDuration}</td>
                            <td>{group.projects.join(', ')}</td>
                            {showMeasurements && group.measurements.map((measurement, mIndex) => (
                                <td key={`measurement-${mIndex}-${index}`}>
                                    <span title={Array.from(measurement.measurementNames).join('\n')}>
                                        {measurement.total ? measurement.total.toFixed(0) : '-'}
                                    </span>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    };

    const exportToPDF = () => {
        const user = localStorage.getItem("userName");
        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'pt',
            format: 'a4',
        });

        const organization = localStorage.getItem('organization') || 'Your Organization';
        const logo = spurtrack; // Assuming this is defined globally.

        let pageNo = 1;

        // Add header function
        const addHeader = () => {
            doc.addImage(logo, 'PNG', 30, 20, 50, 50);
            doc.setFontSize(18);
            doc.text(organization, 100, 40);
            doc.setFontSize(12);
        };

        // Add footer function, including page number
        const addFooter = (currentPage, totalPages) => {
            doc.setFontSize(10);
            doc.text(`Page ${currentPage} of ${totalPages}`, doc.internal.pageSize.getWidth() - 60, doc.internal.pageSize.getHeight() - 30);
            doc.text('Created with SpurTrack', 30, doc.internal.pageSize.getHeight() - 30);
        };

        // Add content function with a page break
        const addContentWithPageBreaks = () => {
            const content = document.querySelector('#main-content'); // Content before consolidated table

            // First page content
            html2canvas(content).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const imgWidth = 500;
                const pageHeight = doc.internal.pageSize.getHeight();
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                let position = 80;
                let heightLeft = imgHeight;

                // Add main content to the first page
                doc.addImage(imgData, 'PNG', 30, position, imgWidth, imgHeight);
                heightLeft -= (pageHeight - 100);

                // Add consolidated table content on a new page
                if (consolidatedData.length > 0) {
                    doc.addPage();
                    addHeader();

                    const consolidatedSection = document.querySelector('#consolidated-section');
                    html2canvas(consolidatedSection).then((canvas) => {
                        const consolidatedImgData = canvas.toDataURL('image/png');
                        const consolidatedImgHeight = (canvas.height * imgWidth) / canvas.width;
                        let consolidatedPosition = 80;

                        // Add consolidated table to the new page
                        doc.addImage(consolidatedImgData, 'PNG', 30, consolidatedPosition, imgWidth, consolidatedImgHeight);

                        // Now, after all content is added, add footers with total pages
                        const totalPages = doc.internal.getNumberOfPages();
                        for (let i = 1; i <= totalPages; i++) {
                            doc.setPage(i); // Set the current page
                            addFooter(i, totalPages); // Add the footer
                        }

                        // Save the PDF after the last page
                        doc.save('report.pdf');

                        // Send notification after saving the PDF
                        sendNotificationToUser({
                            userName: user,
                            message: 'Your report has been generated successfully!',
                            organization
                        });
                    });
                } else {
                    // If there is no consolidated table, add the footer on the first page.
                    const totalPages = doc.internal.getNumberOfPages();
                    for (let i = 1; i <= totalPages; i++) {
                        doc.setPage(i); // Set the current page
                        addFooter(i, totalPages); // Add the footer
                    }
                    doc.save('report.pdf');
                }
            });
        };

        // Function to send a notification
        const sendNotificationToUser = (notificationData) => {
            fetch(`${uri}/api/notifications`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(notificationData),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                       
                    } else {
                        console.error('Failed to send notification:', data.message);
                    }
                })
                .catch(error => {
                    console.error('Error sending notification:', error);
                });
        };

        addHeader(); // Add header to the first page
        addContentWithPageBreaks(); // Handle content rendering with page breaks
    };

    const exportToExcel = (consolidatedData) => {
        if (!Array.isArray(consolidatedData)) {
            console.error('Error: consolidatedData is not an array');
            return;
        }

        const worksheetData = consolidatedData.map(group => ({
            Group: group.group,
            'Total Duration (hours)': group.totalDuration,
            Projects: group.projects.join(', '),
        }));

        const worksheet = XLSX.utils.json_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Summary');
        XLSX.writeFile(workbook, 'summary_report.xlsx');
    };

    const barData = {
        labels: labels,
        datasets: datasets,
    };

    const groupedDatas = groupByProject(summaryData);
    const pieData = {
        labels: groupedDatas.map(item => item.projectName),
        datasets: [{
            data: groupedDatas.map(item => item.totalDuration),
            backgroundColor: colorPalette,
        }]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: { position: 'top' },
            title: { display: true, text: 'Summary Report by Project and Date (Hours)' },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const value = tooltipItem.raw;
                        return `${tooltipItem.dataset.label}: ${value} hours`; // Use template literals for the tooltip
                    }
                }
            },
        },
        scales: {
            x: { stacked: true },
            y: {
                stacked: true,
                title: { display: true, text: 'Duration (hours)' },
                ticks: { callback: function (value) { return `${value}h`; } }
            },
        },
        onClick: (event, elements) => {
            if (elements.length > 0) {
                const chart = elements[0].element.$context.chart;
                const labelIndex = elements[0].index;
                const clickedLabel = chart.data.labels[labelIndex];

                const projectsForDate = summaryData
                    .filter(item => formatDate(item._id.date) === clickedLabel)
                    .map(item => ({
                        projectName: item._id.projectName,
                        totalTime: formatDurationInHours(item.totalTime),
                    }));

                const totalDuration = projectsForDate.reduce((acc, project) => acc + parseFloat(project.totalTime), 0);

                const projectsWithPercentage = projectsForDate.map(project => ({
                    ...project,
                    percentage: ((parseFloat(project.totalTime) / totalDuration) * 100).toFixed(2),
                }));

                setModalData({
                    date: clickedLabel,
                    projects: projectsWithPercentage,
                    totalDuration: totalDuration.toFixed(2),
                });

                setModalShow(true);

                chart.active = [];
            }
        },
    };

    const totalDurationForPie = groupedDatas.reduce((acc, item) => acc + item.totalDuration, 0).toFixed(2);

    // Dynamically group the data based on selected criteria
    const groupedData = groupData(summaryData, groupBy1, groupBy2, groupBy3);
    const centerTextPlugin = {
        id: 'centerText',
        beforeDraw: (chart) => {
            const ctx = chart.ctx;
            const width = chart.width;
            const height = chart.height;
            const fontSize = (height / 400).toFixed(2); // Adjusted for smaller text

            ctx.restore();
            ctx.font = `${fontSize}em sans-serif`;
            ctx.textBaseline = 'middle';

            const text = `${totalDurationForPie} h`;
            const textX = Math.round((width - ctx.measureText(text).width) / 2);
            const textY = height / 2;

            ctx.fillText(text, textX, textY);
            ctx.save();
        }
    };

    // Use the existing groupedData1 and colors1 variables
    const colors1 = useMemo(() => generateColors(groupedData1.length), [groupedData1.length]);

    return (
        <Container fluid>
            <Row className="mb-4">
                <Col>
                    <Button onClick={exportToPDF}>Export as PDF</Button>
                    {consolidatedData && consolidatedData.length > 0 && (
                        <Button className="ml-2" onClick={() => exportToExcel(consolidatedData)}>
                            Export as Excel
                        </Button>
                    )}
                </Col>
            </Row>
            <div id="report-content">
                <div id="main-content">
                    <Row>
                        <Col lg={8}>
                            {/* Bar Chart on the left side */}
                            <Bar data={barData} options={options} />
                        </Col>
                        <Col lg={4}>
                            {/* Doughnut chart on the right side */}
                            <h4>Project Time Distribution</h4>
                            <div style={{ position: 'relative', width: '100%', margin: 'auto' }}>
                                <Doughnut
                                    data={pieData}
                                    options={{
                                        responsive: true,
                                        plugins: {
                                            tooltip: {
                                                callbacks: {
                                                    label: function (tooltipItem) {
                                                        return `${tooltipItem.label}: ${tooltipItem.raw} hours`;
                                                    }
                                                }
                                            },
                                            legend: {
                                                display: true,
                                                position: 'bottom',
                                            },
                                            title: {
                                                display: true,
                                                text: `Total Time: ${totalDurationForPie} hours`
                                            }
                                        }
                                    }}
                                    plugins={[centerTextPlugin]} // Register custom plugin for center text
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={4}>
                            <Form.Group controlId="groupBy1">
                                <Form.Label>Group By 1</Form.Label>
                                <Form.Control as="select" value={groupBy1} onChange={(e) => handleGroupChange(e, setGroupBy1)}>
                                    <option value="">Select...</option>
                                    {getFilteredOptions(groupBy2, groupBy3, groupBy1).map(option => (
                                        <option key={option} value={option}>{option}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group controlId="groupBy2">
                                <Form.Label>Group By 2</Form.Label>
                                <Form.Control as="select" value={groupBy2} onChange={(e) => handleGroupChange(e, setGroupBy2)}>
                                    <option value="">Select...</option>
                                    {getFilteredOptions(groupBy1, groupBy3, groupBy2).map(option => (
                                        <option key={option} value={option}>{option}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group controlId="groupBy3">
                                <Form.Label>Group By 3</Form.Label>
                                <Form.Control as="select" value={groupBy3} onChange={(e) => handleGroupChange(e, setGroupBy3)}>
                                    <option value="">Select...</option>
                                    {getFilteredOptions(groupBy1, groupBy2, groupBy3).map(option => (
                                        <option key={option} value={option}>{option}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>

                    {groupBy1 === 'Task' ? (
                        <>
                            <Row className="mb-4 justify-content-center">
                                <Col md={8}>
                                    {groupedData1.length > 0 && (
                                        <div style={{ height: '400px' }}>
                                            <Doughnut
                                                data={getChartData(groupedData1, colors1)}
                                                options={chartOptions}
                                            />
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {groupedData1.length > 0 && renderTable(groupedData1, colors1, groupBy1)}
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <Row>
                            <Col md={6}>
                                {groupedData1.length > 0 && (
                                    <div style={{ height: '400px' }}>
                                        <Doughnut
                                            data={getChartData(groupedData1, colors1)}
                                            options={chartOptions}
                                        />
                                    </div>
                                )}
                            </Col>
                            <Col md={6}>
                                {groupedData1.length > 0 && renderTable(groupedData1, colors1, groupBy1)}
                            </Col>
                        </Row>
                    )}

                    {groupedData2.length > 0 && (
                        <Row className="mb-4">
                            <h4>Group 2 - {groupBy2}:</h4>
                            <Col lg={6} style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '100%', textAlign: 'center' }}>
                                    <div style={{ width: '75%' }}>
                                        <Doughnut
                                            data={{
                                                labels: groupedData2.map(g => g.group),
                                                datasets: [{
                                                    data: groupedData2.map(g => g.totalDuration),
                                                    backgroundColor: groupedData2.map((_, index) => colorPalette[index % colorPalette.length]),
                                                }]
                                            }}
                                            options={{
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                plugins: {
                                                    tooltip: {
                                                        callbacks: {
                                                            label: function (context) {
                                                                const duration = context.raw;
                                                                return `${duration} hours`;  // Display unit in tooltip
                                                            }
                                                        }
                                                    },
                                                    legend: {
                                                        display: false
                                                    }
                                                }
                                            }}
                                            height={200}
                                            width={200}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                {renderTable(groupedData2, groupedData2.map((_, index) => colorPalette[index % colorPalette.length]), groupBy2)}
                            </Col>
                        </Row>
                    )}

                    {groupedData3.length > 0 && (
                        <Row className="mb-4">
                            <h4>Group - 3: {groupBy3}:</h4>
                            <Col lg={6} style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '100%', textAlign: 'center' }}>
                                    <div style={{ width: '75%' }}>
                                        <Doughnut
                                            data={{
                                                labels: groupedData3.map(g => g.group),
                                                datasets: [{
                                                    data: groupedData3.map(g => g.totalDuration),
                                                    backgroundColor: groupedData3.map((_, index) => colorPalette[index % colorPalette.length]),
                                                }]
                                            }}
                                            options={{
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                plugins: {
                                                    tooltip: {
                                                        callbacks: {
                                                            label: function (context) {
                                                                const duration = context.raw;
                                                                return `${duration} hours`;  // Display unit in tooltip
                                                            }
                                                        }
                                                    },
                                                    legend: {
                                                        display: false
                                                    }
                                                }
                                            }}
                                            height={200}
                                            width={200}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                {renderTable(groupedData3, groupedData3.map((_, index) => colorPalette[index % colorPalette.length]), groupBy3)}
                            </Col>
                        </Row>
                    )}
                </div>
                <div id="consolidated-section">
                    {consolidatedData.length > 0 && (
                        <Row className="mb-4">
                            <h4>Consolidated Table:</h4>
                            <Col lg={6} style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '100%', textAlign: 'center' }}>
                                    {renderConcentricDoughnut()}
                                </div>
                            </Col>
                            <Col lg={6}>
                                {renderTable(consolidatedData, consolidatedData.map((_, index) => colorPalette[index % colorPalette.length]), groupBy1)}
                            </Col>
                        </Row>
                    )}
                </div>
            </div>
        </Container>
    );
};

export default SummaryReportChart;